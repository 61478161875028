import React, { ReactNode } from "react";
import { classNames } from "../../util/strings";

export type ListEntry = {
  key: string;
  value?: ReactNode;
};

type PDescriptionListProps = {
  title?: string | null;
  description?: string | null;
  entries: ListEntry[];
};

const PDescriptionListComponent = (props: PDescriptionListProps) => {
  const { title, description, entries } = props;

  const hasTitleOrDescription = title !== null || description !== null;

  return (
    <div>
      {hasTitleOrDescription ? (
        <div className="px-4 sm:px-0">
          {title ? (
            <h3 className="text-base font-semibold leading-7 text-p-black-lighter">
              {title}
            </h3>
          ) : null}
          {description ? (
            <p className="mt-1 max-w-2xl text-sm leading-6 text-p-black-lightest">
              {description}
            </p>
          ) : null}
        </div>
      ) : null}
      <div className={classNames(hasTitleOrDescription ? "mt-6" : null)}>
        <dl className="divide-y divide-primary/10">
          {entries.map((entry, index) => (
            <div
              key={entry.key}
              className={classNames(
                "px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3",
                index % 2 === 0 ? "bg-primary/5" : "bg-p-white",
              )}
            >
              <dt className="text-sm font-medium leading-6 text-p-black-lighter">
                {entry.key}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-p-black-lighter sm:col-span-2 sm:mt-0">
                {entry.value ?? "--"}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};

PDescriptionListComponent.defaultProps = {
  title: null,
  description: null,
};

export default PDescriptionListComponent;
