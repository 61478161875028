import React from "react";
import { classNames } from "../../util/strings";

type PBadgeProps = {
  content: string;
  onClick?: (() => void) | null;
  onCancelClick?: (() => void) | null;
  className?: string | null;
};

const PBadgeComponent = (props: PBadgeProps) => {
  const { content, onClick, onCancelClick, className } = props;

  const toReturn = (
    <span
      className={classNames(
        "inline-flex items-center px-2 py-1 text-xs font-medium rounded-full justify-center whitespace-pre",
        className,
        onClick
          ? "hover:brightness-95 hover:underline bg-primary/20 text-primary ring-1 ring-inset ring-primary/20"
          : null,
      )}
    >
      {content}
      {onCancelClick && (
        <button
          type="button"
          className="group relative ml-1 -mr-1 h-3.5 w-3.5 hover:bg-p-black/20"
          onClick={onCancelClick}
        >
          <span className="sr-only">Remove</span>
          <svg
            viewBox="0 0 14 14"
            className="h-3.5 w-3.5 stroke-p-black/50 group-hover:stroke-p-black/75"
          >
            <path d="M4 4l6 6m0-6l-6 6" />
          </svg>
          <span className="absolute -inset-1" />
        </button>
      )}
    </span>
  );

  if (onClick) {
    return (
      <button type="button" onClick={onClick}>
        {toReturn}
      </button>
    );
  }

  return toReturn;
};

PBadgeComponent.defaultProps = {
  onClick: null,
  onCancelClick: null,
  className: null,
};

export default PBadgeComponent;
