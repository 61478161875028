import React, { useMemo, useState } from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { components } from "../../../../api/spec";
import PBaseDialog from "../../../ui/dialogs/PBaseDialog";
import PRadioButtons from "../../../ui/input/PRadioButtons";
import { useBusyWatcher } from "../../../../util/hooks";
import PTextArea from "../../../ui/input/PTextArea";
import PInput from "../../../ui/input/PInput";
import PButton from "../../../ui/buttons/PButton";
import PToggle from "../../../ui/input/PToggle";
import PLabel from "../../../ui/input/PLabel";
import { classNames } from "../../../../util/strings";

export type EditQuizQuestionDialogProps = {
  question: components["schemas"]["QuizQuestion"] | null;
};

type innerProps = EditQuizQuestionDialogProps & {
  onSaveClicked: (question: components["schemas"]["QuizQuestion"]) => void;
  onCancelClicked?: (() => void) | null;
};

const questionTypes: {
  value: components["schemas"]["QuestionTypeEnum"];
  label: string;
}[] = [
  {
    value: "long_text",
    label: "Long Text",
  },
  {
    value: "multiple_choice",
    label: "Multiple Choice",
  },
  {
    value: "opinion_scale",
    label: "Opinion Scale",
  },
];

type Choice = {
  key: string; // Unique identifier
  value: string; // Choice text
};

const EditQuizQuestionDialogComponent = (props: innerProps) => {
  const { question, onSaveClicked, onCancelClicked } = props;

  const providedQuestionIndex = useMemo(
    () =>
      question
        ? questionTypes.findIndex((qt) => qt.value === question.question_type)
        : null,
    [],
  );
  const [title, setTitle] = useState<string>(question?.title ?? "");
  const [description, setDescription] = useState<string>(
    question?.description ?? "",
  );
  const [choicesCount, setChoicesCount] = useState<number>(
    question?.choices?.length ?? 2,
  );
  const [choices, setChoices] = useState<Choice[]>(
    question?.choices?.map((choice) => ({
      key: crypto.randomUUID(),
      value: choice.label,
    })) ?? [
      { key: crypto.randomUUID(), value: "" },
      { key: crypto.randomUUID(), value: "" },
    ],
  );
  const [questionType, setQuestionType] = useState<
    components["schemas"]["QuizQuestion"]["question_type"]
  >(question?.question_type ?? "long_text");
  const [allowMultiple, setAllowMultiple] = useState<boolean>(
    question?.allow_multiple_selection ?? false,
  );
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [busy, _] = useBusyWatcher();

  const canSubmit = (): boolean => {
    if (title.length === 0) {
      return false;
    }
    if (description.length === 0) {
      return false;
    }
    if (questionType === "multiple_choice") {
      if (choices.length < 2) {
        return false;
      }
      if (choices.filter((c) => c.value.length > 0).length < 2) {
        return false;
      }
    }
    return true;
  };

  const emitQuestion = () => {
    setIsOpen(false);
    const toEmit: components["schemas"]["QuizQuestion"] = {
      title,
      description,
      question_type: questionType,
      allow_multiple_selection: allowMultiple,
      choices: choices.map((c) => ({
        label: c.value,
      })),
    };
    onSaveClicked(toEmit);
  };

  const cancel = () => {
    setIsOpen(false);
    if (onCancelClicked) {
      onCancelClicked();
    }
  };

  const getTitlePlaceholder = (): string => {
    switch (questionType) {
      case "long_text":
        return "ex: What's one thing I do that lets you know I care about you?";
      case "multiple_choice":
        return "ex: Which of these best describes my sense of humor?";
      case "opinion_scale":
        return "ex: On a scale of 1 to 10, how supportive do you feel I am as a friend?";
      default:
        throw new Error(`Unknown question type: ${questionType}`);
    }
  };

  const getDescriptionPlaceholder = (): string => {
    switch (questionType) {
      case "long_text":
        return "ex: Share a specific thing I do that makes you feel cared for in our friendship.";
      case "multiple_choice":
        return "ex: Pick the option that you think best represents how I make you laugh.";
      case "opinion_scale":
        return "ex: Rate my level of supportiveness from 1 (not supportive) to 10 (extremely supportive).";
      default:
        throw new Error(`Unknown question type: ${questionType}`);
    }
  };

  return (
    <PBaseDialog
      title={question !== null ? "Edit Question" : "Add Question"}
      show={isOpen}
      onClose={cancel}
    >
      <div className="flex flex-col gap-3 sm:w-screen sm:max-w-lg">
        <PRadioButtons
          selected={providedQuestionIndex}
          options={questionTypes}
          label="Question Type"
          onSelected={(selectedType) => {
            setQuestionType(
              selectedType.value as components["schemas"]["QuestionTypeEnum"],
            );
          }}
          disabled={busy}
          required
        />
        <PInput
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          disabled={busy}
          required
          type="text"
          maxLength={255}
          onEnterPressed={() => canSubmit() && emitQuestion()}
          placeholder={getTitlePlaceholder()}
        />
        <PTextArea
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          disabled={busy}
          required
          resize={false}
          placeholder={getDescriptionPlaceholder()}
        />
        {questionType === "multiple_choice" ? (
          <>
            <div className="text-left">
              <PLabel label="Choices" required />
              <div className="flex flex-col gap-1">
                {choices.map((choice, index) => (
                  <div key={choice.key} className="flex items-center gap-2">
                    <div>•</div>
                    <PInput
                      value={choice.value}
                      onChange={(e) => {
                        const newChoices = [...choices];
                        newChoices[index] = {
                          key: newChoices[index].key,
                          value: e.target.value,
                        };
                        setChoices(newChoices);
                      }}
                      disabled={busy}
                      required
                      type="text"
                      maxLength={255}
                      className="grow"
                      onEnterPressed={() => canSubmit() && emitQuestion()}
                    />
                    <button
                      aria-label={`remove choice ${index}`}
                      type="button"
                      onClick={() => {
                        const newChoices = [...choices];
                        newChoices.splice(index, 1);
                        setChoices(newChoices);
                        setChoicesCount(choicesCount - 1);
                      }}
                      disabled={busy || choices.length < 3}
                    >
                      <XMarkIcon
                        className={classNames(
                          "h-5 w-5 rounded-md",
                          busy || choices.length < 3
                            ? "cursor-not-allowed bg-p-black-lightest/10 text-p-black-lighter"
                            : "text-danger hover:bg-danger/10",
                        )}
                      />
                    </button>
                  </div>
                ))}
                <div className="flex items-center gap-2">
                  <div className="mr-2">•</div>
                  <div>Other</div>
                </div>
                <PButton
                  kind="secondary"
                  onClick={() => {
                    setChoicesCount(choicesCount + 1);
                    setChoices([
                      ...choices,
                      { key: crypto.randomUUID(), value: "" },
                    ]);
                  }}
                  className="mt-2"
                  disabled={busy || choicesCount >= 10}
                >
                  add choice
                </PButton>
              </div>
            </div>
            <PToggle
              leftLabel="Allow multiple selections?"
              toggled={allowMultiple}
              onToggled={setAllowMultiple}
              disabled={busy}
            />
          </>
        ) : null}
      </div>
      <div className="flex flex-row w-full gap-4">
        <PButton className="w-1/2" kind="secondary" onClick={() => cancel()}>
          cancel
        </PButton>
        <PButton
          className="w-1/2"
          kind="primary"
          disabled={busy || !canSubmit()}
          onClick={emitQuestion}
        >
          {question !== null ? "save" : "add"}
        </PButton>
      </div>
    </PBaseDialog>
  );
};

EditQuizQuestionDialogComponent.defaultProps = {
  onCancelClicked: null,
};

export default EditQuizQuestionDialogComponent;
