import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PNavbarScreen, {
  PNavbarScreenProps,
} from "../../../ui/layout/PNavbarScreen";
import PWizardScreen, {
  PWizardScreenPanel,
} from "../../../ui/layout/PWizardScreen";
import PLoadingScreen from "../../../ui/screens/PLoadingScreen";
import QuizCoreQuestionScreen from "./QuizCoreQuestionScreen";
import QuizAudienceScreen from "./QuizAudienceScreen";
import {
  approveQuiz,
  checkQuizForClarifications,
  createQuiz,
  listQuizQuestions,
  regenerateQuizQuestions,
  setQuizQuestions,
  setQuizRecipients,
} from "../../../../api/reflection/quizzes";
import { waitForTask } from "../../../../util/tasks";
import {
  useActivePageTime,
  useBackTimer,
  useBusyWatcher,
} from "../../../../util/hooks";
import QuizQuestionsScreen from "./QuizQuestionsScreen";
import { components } from "../../../../api/spec";
import { Recipient } from "../RecipientEntryRow";
import RecipientEntryScreen from "./RecipientEntryScreen";
import QuizCreationCompletedScreen from "./QuizCreationCompletedScreen";
import { QuestionAndAnswer } from "../../../ui/screens/PQuestionsFormScreen";
import QuizGoalScreen from "./QuizGoalScreen";
import PTitle from "../../../ui/PTitle";
import { classNames } from "../../../../util/strings";
import {
  backgroundGradient,
  middleColumnContent,
} from "../../../../util/style";
import { LOADING_PHRASES } from "../../../../util/contants";
import QuizClarificationsScreen from "./QuizClarificationsScreen";
import CreateIntroScreen from "./CreateIntroScreen";

enum CreateQuizStage {
  LOADING,
  INTRODUCTION,
  CORE_QUESTION,
  AUDIENCE,
  GOAL,
  CLARIFICATIONS,
  CREATING_QUIZ,
  REVIEW_QUESTIONS,
  ADD_RECIPIENTS,
  COMPLETED,
}

type CreateQuizPageProps = PNavbarScreenProps & {
  viewQuizUrl: string;
  shareableUrl: string;
};

const CreateQuizPageComponent = (props: CreateQuizPageProps) => {
  const { viewQuizUrl, shareableUrl, navbar } = props;

  const [stage, setStage] = useState<CreateQuizStage>(CreateQuizStage.LOADING);
  const [errors, setErrors] = useState<string[]>([]);
  const [coreQuestion, setCoreQuestion] = useState<string>("");
  const [audience, setAudience] = useState<string>("");
  const [goal, setGoal] = useState<string>("");
  const [clarifications, setClarifications] = useState<
    components["schemas"]["ClarifyQuizContentsOutputSerializerWrapper"] | null
  >(null);
  const [quizGuid, setQuizGuid] = useState<string>("");
  const [questions, setQuestions] = useState<
    components["schemas"]["ListQuizQuestionsResponseSerializerWrapper"] | null
  >(null);
  const [isRegenerating, setIsRegenerating] = useState<boolean>(false);
  const [quiz, setQuiz] = useState<
    components["schemas"]["ReflectionQuizRelatedDetailSerializerWrapper"] | null
  >(null);
  const [isBack, asBack] = useBackTimer(stage);
  const getActivePageTime = useActivePageTime();

  const [_, busyWatcher] = useBusyWatcher();

  const goToStage = async (nextStage: CreateQuizStage) => {
    setErrors([]);
    // eslint-disable-next-line default-case,no-empty
    switch (nextStage) {
    }
    setStage(nextStage);
  };

  const onCoreQuestionNextClicked = async (newCoreQuestion: string) => {
    setCoreQuestion(newCoreQuestion);
    await goToStage(CreateQuizStage.AUDIENCE);
  };

  const createAndFetchQuiz = async (
    questionClarification: QuestionAndAnswer | null,
    audienceClarification: QuestionAndAnswer | null,
    goalClarification: QuestionAndAnswer | null,
  ) => {
    setStage(CreateQuizStage.CREATING_QUIZ);

    // First we create the quiz

    const [response, createError] = await createQuiz(
      {
        core_question: coreQuestion,
        core_question_clarifications: questionClarification
          ? [questionClarification]
          : null,
        audience,
        audience_clarifications: audienceClarification
          ? [audienceClarification]
          : null,
        goal,
        goal_clarifications: goalClarification ? [goalClarification] : null,
      },
      [busyWatcher],
    );
    if (createError !== null) {
      setErrors([createError.message!]);
      return;
    }
    const newQuizGuid = response!.content.quiz.guid;
    setQuizGuid(newQuizGuid);

    // Now we wait for the task associated with the quiz generation to complete

    const success = await waitForTask(response!.content.task_id, [busyWatcher]);
    if (!success) {
      setErrors(["failed to wait for the task to complete"]);
      return;
    }

    // The quiz has been successfully generated so let's transition to the stage, which will cause the fetch

    const [quizQuestions, listError] = await listQuizQuestions(
      { quiz: newQuizGuid },
      [busyWatcher],
    );
    if (listError !== null) {
      setErrors([listError.message!]);
      return;
    }
    setQuestions(quizQuestions);

    // Now we can continue to the review stage

    await goToStage(CreateQuizStage.REVIEW_QUESTIONS);
  };

  const onAudienceNextClicked = async (newAudience: string) => {
    setAudience(newAudience);
    await goToStage(CreateQuizStage.GOAL);
  };

  const onGoalNextClicked = async (newGoal: string) => {
    setGoal(newGoal);
    const [newClarifications, error] = await checkQuizForClarifications(
      {
        core_question: coreQuestion,
        audience,
        goal: newGoal,
      },
      [busyWatcher],
    );
    if (error !== null) {
      setErrors([error.message!]);
      return;
    }
    setClarifications(newClarifications);
    await goToStage(CreateQuizStage.CLARIFICATIONS);
  };

  const onClarificationsNextClicked = async (answers: QuestionAndAnswer[]) => {
    const questionClarification = answers[0].answer !== "" ? answers[0] : null;
    const audienceClarification = answers[1].answer !== "" ? answers[1] : null;
    const goalClarification = answers[2].answer !== "" ? answers[2] : null;
    await createAndFetchQuiz(
      questionClarification,
      audienceClarification,
      goalClarification,
    );
  };

  const onSelectQuestionsClicked = async (
    selected: components["schemas"]["SelectedQuizQuestion"][],
  ) => {
    await setQuizQuestions(
      {
        quiz: quizGuid,
        questions: selected,
        is_for_approval: true,
      },
      [busyWatcher],
    );
    await goToStage(CreateQuizStage.ADD_RECIPIENTS);
  };

  const onRegenerateQuestionsClicked = async (
    newQuestions: components["schemas"]["SelectedQuizQuestion"][],
    reason: string,
  ) => {
    setIsRegenerating(true);
    toast.info("Regenerating quiz questions for you...");

    // In order to regenerate questions we have to first set them and then call the regenerate endpoint

    const [__, setQuestionsErrors] = await setQuizQuestions(
      {
        quiz: quizGuid,
        questions: newQuestions,
        is_for_approval: false,
      },
      [busyWatcher],
    );
    if (setQuestionsErrors !== null) {
      setErrors([setQuestionsErrors.message!]);
      setIsRegenerating(false);
      return;
    }
    const [regenerateResponse, regenerateErrors] =
      await regenerateQuizQuestions(
        {
          quiz: quizGuid,
          reason,
        },
        [busyWatcher],
      );
    if (regenerateErrors !== null) {
      setErrors([regenerateErrors.message!]);
      setIsRegenerating(false);
      return;
    }

    // Then we need to wait for the regeneration task to complete

    const success = await waitForTask(regenerateResponse!.content!.task, [
      busyWatcher,
    ]);
    if (!success) {
      setErrors(["failed to regenerate questions"]);
      setIsRegenerating(false);
      return;
    }

    // When completed we need to fetch the newly-created questions

    const [regeneratedQuestions, listErrors] = await listQuizQuestions(
      { quiz: quizGuid },
      [busyWatcher],
    );
    if (listErrors !== null) {
      setErrors([listErrors.message!]);
      setIsRegenerating(false);
      return;
    }
    setQuestions(regeneratedQuestions);
    toast.success("New questions available!");
    setIsRegenerating(false);
  };

  const finishQuizApproval = async () => {
    const [quizDetail, quizDetailError] = await approveQuiz({
      quiz: quizGuid,
      duration_s: getActivePageTime(),
    });
    if (quizDetailError !== null) {
      setErrors([quizDetailError.message!]);
      return;
    }
    setQuiz(quizDetail);
    await goToStage(CreateQuizStage.COMPLETED);
  };

  const onSetRecipientsNextClicked = async (newRecipients: Recipient[]) => {
    const formattedRecipients = [...newRecipients].map((r) => ({
      first_name: r.firstName,
      last_name: r.lastName,
      email: r.emailAddress,
    }));
    const [__, error] = await setQuizRecipients(
      {
        quiz: quizGuid,
        recipients: formattedRecipients,
      },
      [busyWatcher],
    );
    if (error !== null) {
      setErrors([error.message!]);
      return;
    }
    await finishQuizApproval();
  };

  const getElements = (): PWizardScreenPanel[] => [
    {
      stage: CreateQuizStage.LOADING,
      content: (
        <PLoadingScreen
          errors={errors}
          onBackClicked={() => window.history.back()}
        />
      ),
    },
    {
      stage: CreateQuizStage.INTRODUCTION,
      content: (
        <CreateIntroScreen
          onNextClicked={() => goToStage(CreateQuizStage.CORE_QUESTION)}
        />
      ),
    },
    {
      stage: CreateQuizStage.CORE_QUESTION,
      content: (
        <QuizCoreQuestionScreen
          onNextClicked={onCoreQuestionNextClicked}
          onBackClicked={asBack(() => goToStage(CreateQuizStage.INTRODUCTION))}
          errors={errors}
          content={coreQuestion}
        />
      ),
    },
    {
      stage: CreateQuizStage.AUDIENCE,
      content: (
        <QuizAudienceScreen
          onNextClicked={onAudienceNextClicked}
          onBackClicked={asBack(() => goToStage(CreateQuizStage.CORE_QUESTION))}
          content={audience}
          errors={errors}
        />
      ),
    },
    {
      stage: CreateQuizStage.GOAL,
      content: (
        <QuizGoalScreen
          onNextClicked={onGoalNextClicked}
          onBackClicked={asBack(() => goToStage(CreateQuizStage.AUDIENCE))}
          content={goal}
          errors={errors}
        />
      ),
    },
    {
      stage: CreateQuizStage.CLARIFICATIONS,
      content: clarifications && (
        <QuizClarificationsScreen
          questions={[
            clarifications.content.core_question_clarification,
            clarifications.content.audience_clarification,
            clarifications.content.goal_clarification,
          ]}
          onNextClicked={onClarificationsNextClicked}
          onSkipClicked={() => createAndFetchQuiz(null, null, null)}
        />
      ),
    },
    {
      stage: CreateQuizStage.CREATING_QUIZ,
      content: (
        <PLoadingScreen
          errors={errors}
          phrases={LOADING_PHRASES}
          onBackClicked={asBack(() => goToStage(CreateQuizStage.AUDIENCE))}
        />
      ),
    },
    {
      stage: CreateQuizStage.REVIEW_QUESTIONS,
      content: questions !== null && (
        <QuizQuestionsScreen
          questions={questions}
          onNextClicked={onSelectQuestionsClicked}
          onRegenerateClicked={onRegenerateQuestionsClicked}
          isRegenerating={isRegenerating}
        />
      ),
    },
    {
      stage: CreateQuizStage.ADD_RECIPIENTS,
      content: (
        <RecipientEntryScreen
          maxRecipients={10}
          onNextClicked={onSetRecipientsNextClicked}
          onBackClicked={asBack(() =>
            goToStage(CreateQuizStage.REVIEW_QUESTIONS),
          )}
          onSkipClicked={finishQuizApproval}
        />
      ),
    },
    {
      stage: CreateQuizStage.COMPLETED,
      content: quiz && (
        <QuizCreationCompletedScreen
          title={quiz.content.title!}
          description={quiz.content.description!}
          recipients={quiz.content.recipients.map((r) => ({
            firstName: r.first_name!,
            lastName: r.last_name!,
            emailAddress: r.email!,
          }))}
          quizGuid={quizGuid}
          shareableUrl={shareableUrl}
          viewQuizUrl={viewQuizUrl}
        />
      ),
    },
  ];

  useEffect(() => {
    goToStage(CreateQuizStage.INTRODUCTION);
  }, []);

  return (
    <PNavbarScreen
      navbar={{
        ...navbar,
        forceLoading: stage === CreateQuizStage.CREATING_QUIZ || isRegenerating,
      }}
      bgClassname={backgroundGradient}
    >
      <div className={classNames("mb-3", middleColumnContent)}>
        <PTitle title="Creating a new quiz..." />
      </div>
      <PWizardScreen isBack={isBack} elements={getElements()} stage={stage} />
    </PNavbarScreen>
  );
};

export default CreateQuizPageComponent;
