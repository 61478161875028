import React, { PropsWithChildren, useRef } from "react";
import PNav, { PNavProps } from "../PNav";
import { useSize } from "../../../util/hooks";
import PMiddleMarginsLayout from "./PMiddleMarginsLayout";
import { classNames } from "../../../util/strings";

export type PNavbarScreenProps = {
  navbar: PNavProps;
  bgClassname?: string | null;
};

const PNavbarScreenComponent = (
  props: PropsWithChildren<PNavbarScreenProps>,
) => {
  const { navbar, bgClassname, children } = props;

  const navRef = useRef<HTMLDivElement>(null);
  const navSize = useSize(navRef);

  const getTopMarginForBody = (): number => {
    if (navSize) {
      return navSize.height + 3;
    }
    return 76 + 3;
  };

  return (
    <>
      <div
        className={classNames("w-screen fixed top-0 left-0 z-10", bgClassname)}
        ref={navRef}
      >
        <PNav {...navbar} />
      </div>
      <div className="flex-1 w-full mx-auto">
        <div
          className={classNames(bgClassname, "min-h-full h-full pb-20 sm:pb-0")}
        >
          <div style={{ height: `${getTopMarginForBody()}px` }} />
          <PMiddleMarginsLayout>{children}</PMiddleMarginsLayout>
        </div>
      </div>
    </>
  );
};

PNavbarScreenComponent.defaultProps = {
  bgClassname: null,
};

export default PNavbarScreenComponent;
