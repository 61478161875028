import React, { ReactNode } from "react";
import {
  DocumentChartBarIcon,
  LinkIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import { PlusIcon, StopIcon } from "@heroicons/react/20/solid";
import PTitle from "../../../ui/PTitle";
import { components } from "../../../../api/spec";
import PSkeleton from "../../../ui/PSkeleton";
import PSubtitle from "../../../ui/PSubtitle";
import PBadge from "../../../ui/PBadge";
import { classNames } from "../../../../util/strings";
import QuizQuestionCard from "../QuizQuestionCard";
import PErrors from "../../../ui/input/PErrors";
import PButton from "../../../ui/buttons/PButton";
import QuizRecipientSummaryRow from "./QuizRecipientSummaryRow";
import { useBusyWatcher } from "../../../../util/hooks";
import { getPathAtCurrentUrl } from "../../../../util/url";
import QuizScreenCell from "./QuizScreenCell";
import PDescriptionList, { ListEntry } from "../../../ui/PDescriptionList";
import { useAsyncConfirmationDialog } from "../../../ui/dialogs/hooks";
import { useAddRecipientDialog } from "./hooks";
import { Recipient } from "../RecipientEntryRow";

type ViewQuizScreenProps = {
  quiz:
    | components["schemas"]["ReflectionQuizRelatedDetailSerializerWrapper"]
    | null;
  onNudgeClicked: (
    recipient: components["schemas"]["ReflectionQuizRecipientSummary"],
  ) => void;
  onDeleteRecipientClicked: (
    recipient: components["schemas"]["ReflectionQuizRecipientSummary"],
  ) => void;
  onAddRecipientClicked: (newRecipient: Recipient) => void;
  onDelete: () => void;
  onStop: () => void;
  reportUrl: string;
  shareableUrl: string;
  errors: string[];
};

const ViewQuizScreenComponent = (props: ViewQuizScreenProps) => {
  const {
    quiz,
    onNudgeClicked,
    reportUrl,
    errors,
    shareableUrl,
    onDeleteRecipientClicked: onDeleteRecipientClickedInput,
    onAddRecipientClicked: onAddRecipientClickedInput,
    onDelete,
    onStop,
  } = props;

  const [busy, _] = useBusyWatcher();
  const { dialog, confirm } = useAsyncConfirmationDialog();
  const addRecipientDialog = useAddRecipientDialog();

  const onCopyLinkClicked = async () => {
    const shareableLink = `${shareableUrl}?quiz=${quiz!.content.guid}`;
    const fullUrl = getPathAtCurrentUrl(shareableLink);
    await navigator.clipboard.writeText(fullUrl);
    toast.success("Link copied to clipboard");
  };

  const onStopQuizClicked = async () => {
    const result = await confirm({
      title: "Complete Quiz?",
      body: "Are you sure you want to complete this quiz? This will prevent any further responses and generate a report based on the current responses. This cannot be undone.",
      kind: "info",
    });
    if (!result) {
      return;
    }
    onStop();
  };

  const onDeleteQuizClicked = async () => {
    const result = await confirm({
      title: "Delete Quiz?",
      body: "Are you sure you want to delete this quiz? This cannot be undone.",
      kind: "danger",
    });
    if (!result) {
      return;
    }
    onDelete();
  };

  const getQuestionContents = (
    questions: readonly components["schemas"]["QuestionAndAnswer"][] | null,
  ): ReactNode => {
    if (questions === null) {
      return null;
    }
    return (
      <div className="flex flex-col gap-3">
        {questions.map((question) => (
          <div key={question.question}>
            <div className="text-sm font-semibold text-p-black-lighter mb-2">
              {question.question}
            </div>
            <div className="text-sm text-p-black-lighter whitespace-pre-line">
              {question.answer}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const getBackgroundEntries = (): ListEntry[] => {
    if (!quiz) {
      return [];
    }
    return [
      { key: "Core Question", value: quiz.content.core_question },
      {
        key: "Core Question Details",
        value: getQuestionContents(quiz.content.core_question_clarifications),
      },
      { key: "Audience", value: quiz.content.audience },
      {
        key: "Audience Details",
        value: getQuestionContents(quiz.content.audience_clarifications),
      },
      { key: "Goal", value: quiz.content.goal },
      {
        key: "Goal Details",
        value: getQuestionContents(quiz.content.goal_clarifications),
      },
    ];
  };

  const onDeleteRecipientClicked = async (
    recipient: components["schemas"]["ReflectionQuizRecipientSummary"],
  ) => {
    const result = await confirm({
      title: "Delete Recipient?",
      body: `Are you sure you want to delete '${recipient.first_name} ${recipient.last_name}'? This cannot be undone.`,
      kind: "danger",
    });
    if (!result) {
      return;
    }
    onDeleteRecipientClickedInput(recipient);
  };

  const onAddRecipientClicked = async () => {
    const newRecipient = await addRecipientDialog.promptForRecipient();
    if (newRecipient === null) {
      return;
    }
    onAddRecipientClickedInput(newRecipient);
  };

  if (errors.length > 0) {
    return <PErrors errors={errors} />;
  }

  if (!quiz) {
    return (
      <div>
        <div className="px-4 sm:px-0">
          <PTitle title="loading..." />
        </div>
        <div className="flex flex-col gap-5">
          <PSkeleton />
          <PSkeleton />
          <PSkeleton />
        </div>
      </div>
    );
  }

  return (
    <>
      <div>
        <div>
          <dl className="grid grid-cols-1 sm:grid-cols-2">
            <QuizScreenCell>
              <div className="flex flex-col gap-2 items-start sm:flex-row sm:gap-3 sm:items-center">
                <PTitle title={quiz.content.title!} />
                <PBadge
                  content={
                    quiz.content.is_completed ? "Completed" : "In Progress"
                  }
                  className={classNames(
                    "font-semibold",
                    quiz.content.is_completed
                      ? "bg-success text-p-black-lighter"
                      : "bg-secondary text-p-black-lighter",
                  )}
                />
              </div>
              <p className="mt-2 sm:mt-1 max-w-2xl text-sm leading-6 text-p-black-lighter">
                {quiz.content.description}
              </p>
            </QuizScreenCell>
            <QuizScreenCell>
              <div className="sm:flex flex-row justify-end">
                <div className="flex flex-col sm:grid grid-cols-2 gap-2 text-sm max-w-xl">
                  <PButton
                    kind="secondary"
                    icon={<LinkIcon className="w-5 h-5" />}
                    disabled={busy || !quiz || quiz.content.is_completed}
                    onClick={onCopyLinkClicked}
                    disabledTooltip={
                      quiz?.content.is_completed ? "Quiz is completed" : null
                    }
                  >
                    copy shareable link
                  </PButton>
                  <PButton
                    kind="secondary"
                    icon={<StopIcon className="w-5 h-5" />}
                    disabled={busy || !quiz || quiz.content.is_completed}
                    disabledTooltip={
                      quiz?.content.is_completed ? "Quiz is completed" : null
                    }
                    onClick={onStopQuizClicked}
                  >
                    complete quiz
                  </PButton>
                  <PButton
                    kind="primary"
                    icon={<DocumentChartBarIcon className="w-5 h-5" />}
                    href={`${reportUrl}?quiz=${quiz.content.guid}`}
                    disabled={
                      busy || !quiz || !quiz.content.is_report_available
                    }
                    disabledTooltip={
                      !quiz?.content.is_report_available
                        ? "Report will be available after the quiz has been completed and the results have been processed. Try refreshing the page in a few minutes."
                        : null
                    }
                  >
                    view report
                  </PButton>
                  <PButton
                    kind="danger"
                    icon={<TrashIcon className="w-5 h-5" />}
                    disabled={busy || !quiz}
                    onClick={onDeleteQuizClicked}
                  >
                    delete quiz
                  </PButton>
                </div>
              </div>
            </QuizScreenCell>
          </dl>
        </div>
        <div>
          <dl className="grid grid-cols-1">
            <QuizScreenCell>
              <dt className="mb-2">
                <PSubtitle title="Quiz Configuration" />
              </dt>
              <PDescriptionList entries={getBackgroundEntries()} />
            </QuizScreenCell>
          </dl>
        </div>
        <div>
          <dl className="grid grid-cols-1">
            <QuizScreenCell>
              <dt>
                <div className="flex flex-row justify-between items-end">
                  <div>
                    <PSubtitle title="Recipients" />
                    <div className="mt-1 text-sm leading-6 text-p-black-lighter sm:mt-2">
                      <span className="font-bold">
                        {quiz.content.recipient_count}
                      </span>{" "}
                      emails sent,{" "}
                      <span className="font-bold">
                        {quiz.content.opened_recipient_count}
                      </span>{" "}
                      quizzes opened,{" "}
                      <span className="font-bold">
                        {quiz.content.completed_recipient_count}
                      </span>{" "}
                      quizzes completed
                    </div>
                  </div>
                  <div>
                    <PButton
                      kind="primary"
                      icon={<PlusIcon className="w-5 h-5" />}
                      onClick={onAddRecipientClicked}
                    >
                      add recipient
                    </PButton>
                  </div>
                </div>
              </dt>
              <dd>
                <div className="flow-root mt-1 sm:mt-2">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-p-black-lightest/10">
                        <thead className="bg-p-black-lightest/10">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-p-black whitespace-pre"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-p-black whitespace-pre"
                            >
                              Email
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-p-black whitespace-pre"
                            >
                              Status
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-p-black whitespace-pre"
                            >
                              Last Nudged At
                            </th>
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 whitespace-pre"
                            >
                              <span className="sr-only">Actions</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-p-black-lightest/10">
                          {quiz.content.recipients.map((recipient) => (
                            <QuizRecipientSummaryRow
                              key={recipient.guid}
                              recipient={recipient}
                              onNudgeClicked={
                                !quiz.content.is_completed
                                  ? () => onNudgeClicked(recipient)
                                  : null
                              }
                              onDeleteClicked={
                                !quiz.content.is_completed
                                  ? () => onDeleteRecipientClicked(recipient)
                                  : null
                              }
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </dd>
            </QuizScreenCell>
          </dl>
        </div>
        <div>
          <PSubtitle title="Questions" className="mb-2" />
          <dl className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 text-sm">
            {quiz.content.questions ? (
              quiz.content.questions.map((question, index) => (
                <QuizScreenCell key={question.title}>
                  <QuizQuestionCard
                    key={question.title}
                    question={question}
                    index={index}
                  />
                </QuizScreenCell>
              ))
            ) : (
              <div className="italic">
                No questions found in association with this quiz
              </div>
            )}
          </dl>
        </div>
      </div>
      {dialog}
      {addRecipientDialog.dialog}
    </>
  );
};

export default ViewQuizScreenComponent;
