export const classNames = (...classes: (string | undefined | null)[]): string =>
  classes.filter(Boolean).join(" ");

export const durationFromMs = (ms: number): string => `duration-[${ms}ms]`;

export const shuffleStrings = (array: string[]): string[] =>
  array
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
