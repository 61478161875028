import React, { useState } from "react";
import { components } from "../../../api/spec";
import PBadge from "../../ui/PBadge";
import PCheckbox from "../../ui/input/PCheckbox";
import { classNames } from "../../../util/strings";
import { useUniqueId } from "../../../util/hooks";
import PTooltip from "../../ui/PTooltip";
import PIconEditButton from "../../ui/buttons/PIconEditButton";

type QuizQuestionCardProps = {
  question: components["schemas"]["QuizQuestion"];
  index?: number | null;
  onSelected?: (() => void) | null;
  selected?: boolean | null;
  disabled?: boolean;
  disabledTooltip?: string | null;
  onEditClicked?: (() => void) | null;
};

export const QuestionTitleFromType = (
  type: components["schemas"]["QuestionTypeEnum"],
): string => {
  switch (type) {
    case "multiple_choice":
      return "Multiple Choice";
    case "short_text":
      return "Short Text";
    case "opinion_scale":
      return "Scale 1-10";
    case "long_text":
      return "Long Text";
    default:
      throw new Error(`Unknown question type: ${type}`);
  }
};

const QuizQuestionCardComponent = (props: QuizQuestionCardProps) => {
  const {
    question,
    index,
    onSelected: onSelectedInput,
    selected: selectedInput,
    disabled,
    disabledTooltip,
    onEditClicked,
  } = props;

  const tooltipId = useUniqueId();
  const [selected, setSelected] = useState<boolean>(selectedInput || false);

  const onSelectedChanged = () => {
    setSelected(!selected);
    if (onSelectedInput) {
      onSelectedInput();
    }
  };

  const showCheckBox =
    onSelectedInput !== null && onSelectedInput !== undefined;
  const showEditButton = onEditClicked !== null && onEditClicked !== undefined;
  const hasSideBar = showCheckBox || showEditButton;

  const getContent = () => (
    <div className="grid grid-cols-12">
      <div
        className={classNames(
          "col-span-1 font-bold self-center sm:self-start",
          "flex flex-col items-start gap-3",
          !hasSideBar ? "hidden sm:block" : null,
        )}
      >
        {index !== null && index !== undefined ? `${index + 1}.` : null}
        {showCheckBox ? (
          <PCheckbox
            onChange={onSelectedChanged}
            checked={selected}
            disabled={disabled}
            data-tooltip-id={tooltipId}
            data-tooltip-content={
              disabled && disabledTooltip ? disabledTooltip : null
            }
          />
        ) : null}
        {showEditButton ? (
          <PIconEditButton
            onClick={onEditClicked}
            disabled={disabled}
            tooltip="edit question"
          />
        ) : null}
      </div>
      <div
        className={classNames(
          "flex flex-col gap-3 sm:col-span-11",
          !hasSideBar ? "col-span-12" : "col-span-11",
        )}
      >
        <div className="flex flex-col-reverse items-start sm:flex-row gap-3 justify-between sm:items-center">
          <div className="font-bold">
            <span className="sm:hidden">
              {index !== null && index !== undefined ? `${index + 1}. ` : null}
            </span>
            {question.title}
          </div>
          <PBadge
            className="bg-secondary"
            content={QuestionTitleFromType(question.question_type)}
          />
        </div>
        <div>{question.description}</div>
        {question.question_type === "multiple_choice" && question.choices ? (
          <ul className="list-disc list-inside">
            {question.choices.map((choice, i) => (
              <li key={`${choice.label}_${i.toString()}`}>{choice.label}</li>
            ))}
            <li key="other-final">Other</li>
          </ul>
        ) : null}
      </div>
    </div>
  );
  return (
    <>
      <div
        className={classNames(
          "p-3 rounded-lg",
          showCheckBox && selected ? "bg-primary/20" : null,
          showCheckBox && disabled
            ? "bg-p-black-lightest/5 text-p-black-lighter/60"
            : null,
        )}
      >
        {getContent()}
      </div>
      <PTooltip id={tooltipId} />
    </>
  );
};

QuizQuestionCardComponent.defaultProps = {
  index: null,
  onSelected: null,
  selected: null,
  disabled: false,
  disabledTooltip: null,
  onEditClicked: null,
};

export default QuizQuestionCardComponent;
