import React, { Fragment, PropsWithChildren, ReactElement } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { classNames } from "../../../util/strings";

type PBaseDialogProps = {
  title?: string;
  titleIcon?: ReactElement;
  titleIconBackgroundColor?: string;
  show: boolean;
  onClose: () => void;
  className?: string | null;
};

const PBaseDialogComponent = (props: PropsWithChildren<PBaseDialogProps>) => {
  const {
    title,
    titleIcon,
    titleIconBackgroundColor,
    show,
    onClose,
    children,
    className,
  } = props;

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-p-black-lighter bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  "relative transform",
                  "rounded-lg bg-p-white shadow-xl",
                  "px-4 pb-4 pt-5",
                  "text-left",
                  "transition-all",
                  "w-full sm:w-auto min-w-[24rem] max-w-3xl",
                  className,
                )}
              >
                {title ? (
                  <div className="text-center mb-4">
                    {titleIcon ? (
                      <div
                        className={classNames(
                          "mx-auto flex h-12 w-12 items-center justify-center rounded-full mb-2 p-2",
                          titleIconBackgroundColor ?? "bg-secondary",
                        )}
                      >
                        {titleIcon}
                      </div>
                    ) : null}
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-p-black"
                    >
                      {title}
                    </Dialog.Title>
                  </div>
                ) : null}
                <div className="flex flex-col gap-4 items-stretch text-center">
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

PBaseDialogComponent.defaultProps = {
  title: undefined,
  titleIcon: undefined,
  titleIconBackgroundColor: undefined,
  className: null,
};

export default PBaseDialogComponent;
